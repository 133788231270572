"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function isEqual(a, b) {
    return a === b;
}
exports.config = {
    production: isEqual('false', 'true'),
    hmr: isEqual('false', 'true'),
    services: {
        data: {
            endpoint: 'wss://dev-hasura.buymyplace.com.au/v1/graphql',
        },
        googleMaps: {
            key: '***'
        },
        analytics: {
            segment: {
                enabled: isEqual('false', 'true'),
                writeKey: '***',
            },
        },
        sentry: {
            sentryDsnVendor: '***',
        },
    },
    application: {
        branch: 'local',
        buildDate: 'Wed, 06 Nov 2024 12:56:38 GMT',
        buildNumber: 'undefined',
        version: '0.0.10',
        gitHead: 'undefined'
    }
};
